import React from 'react';
import './fullWidthCellRenderer.css';

export default (params) => {
    let {
        id, lastName, firstName, middleName, birthday,
        address, tel1, tel2, tel3,
        email, baptized, pastor,
        rebuke, excommunicated, moved, died, ministry, leader, notes
    } = params.node.data;
    let user = JSON.parse(localStorage.getItem('reUser'));
    const isReadOnly = (user, pastor) => {
        if(user.pastor === pastor) return false;
        if(parseInt(user.admin) > 0) return false;

        return true;
    }
    const getCell = data => {
        let value = data.value || "";
        return (
            <input
                id={data.id}
                className={'editable-cell'}
                readOnly={isReadOnly(data.user, data.pastor)}
                style={{
                    width: value.length < 1 ? "100%" : ((value.length + 1) * 8) + "px",
                    border: value.length < 1 ? "1px solid #d5cece" : "none",
                }}
                defaultValue={value}
                onChange={e => {
                    params.data[e.target.id] = e.target.value;
                    params.api.updatedMember = params.data;
                    params.api.onChangeMember.isChanged = true;
                }}
            />
        )
    }

    params.node.setSelected(false);

    return (
        <div
            className={'member-detailed-container'}
            // onClick={params.api.setFullWidthRowId}
        >
            <div className={'detailed-flex-row'}>
                <div className={'detailed-item-row'} style={{padding: 10, borderStyle: "none"}}>
                    {id}
                </div>
                <div className={'detailed-item-column'} style={{padding: 10, width: '80%'}}>
                    <div className={'close-button-row'} style={{width: "100%", backgroundColor: ''}}>
                        <div className={'group-cells-row'}>
                            {getCell({id: 'lastName', value: lastName, user, pastor})}
                            {getCell({id: 'firstName', value: firstName, user, pastor})}
                            {getCell({id: 'middleName', value: middleName, user, pastor})}
                        </div>
                        <div className={'close-button'} onClick={() => {
                            params.api.setFullWidthRowId();
                            params.api.saveUpdatedMemberData();
                        }}>X</div>
                    </div>

                    <div className={'group-blocks-row'}>
                        <div className={'main-member-info-block'}>
                            <div className={'group-cells-row'}>
                                <div>Дата народження:</div>
                                {getCell({id: 'birthday', value: birthday, user, pastor})}
                            </div>
                            {getCell({id: 'address', value: address, user, pastor})}
                            <div className={'group-cells-row'}>
                                {getCell({id: 'tel1', value: tel1, user, pastor})}
                                {getCell({id: 'tel2', value: tel2, user, pastor})}
                                {getCell({id: 'tel3', value: tel3, user, pastor})}
                            </div>
                            {getCell({id: 'email', value: email, user, pastor})}
                            <div className={'group-cells-row'}>
                                <div>Хрещення:</div>
                                {getCell({id: 'baptized', value: baptized, user, pastor})}
                            </div>
                            <div className={'group-cells-row'}>
                                {/* todo: only admin can change pastor. Not self*/}
                                <div>Відповідальний пастор:</div>
                                {
                                    parseInt(user.admin) > 0
                                        ? getCell({id: 'pastor', value: pastor, user, pastor})
                                        : <div>{pastor}</div>
                                }
                            </div>
                        </div>
                        <div className={'notes-member-block'}>
                            <textarea
                                id={id}
                                rows={5}
                                style={{width: "100%"}}
                                readOnly={isReadOnly(user, pastor)}
                                defaultValue={notes}
                                onChange={e => {
                                    params.data['notes'] = e.target.value;
                                    params.api.updatedMember = params.data;
                                    params.api.onChangeMember.isChanged = true;
                                }}
                            />
                        </div>
                        <div className={'additional-member-info-block'}>
                            {!isReadOnly(user, pastor) || died
                                ? <div className={'group-cells-row'}>
                                    <div>Лідер служіння:</div>
                                    {getCell({id: 'leader', value: leader, user, pastor})}
                                </div>
                                : <div></div>
                            }
                            {!isReadOnly(user, pastor) || died
                                ? <div className={'group-cells-row'}>
                                    <div>Служіння:</div>
                                    {getCell({id: 'ministry', value: ministry, user, pastor})}
                                </div>
                                : <div></div>
                            }
                            {!isReadOnly(user, pastor) || rebuke
                                ? <div className={'group-cells-row'}>
                                    <div>На церковному зауважені:</div>
                                    {getCell({id: 'rebuke', value: rebuke, user, pastor})}
                                </div>
                                : <div></div>
                            }
                            {!isReadOnly(user, pastor) || excommunicated
                                ? <div className={'group-cells-row'}>
                                    <div>Відлучений:</div>
                                    {getCell({id: 'excommunicated', value: excommunicated, user, pastor})}
                                </div>
                                : <div></div>
                            }
                            {!isReadOnly(user, pastor) || moved
                                ? <div className={'group-cells-row'}>
                                    <div>В іншу церкву:</div>
                                    {getCell({id: 'moved', value: moved, user, pastor})}
                                </div>
                                : <div></div>
                            }
                            {!isReadOnly(user, pastor) || died
                                ? <div className={'group-cells-row'}>
                                    <div>У вічності:</div>
                                    {getCell({id: 'died', value: died, user, pastor})}
                                </div>
                                : <div></div>
                            }
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

import React, { useState } from 'react';
import './Filter.css';

const FilterButton = (props) => {
    return (
        <svg
            fill="#000000"
            height="25px"
            width="25px"
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 518.462 518.462"
            onClick={() => props.setIsOpen(!props.isOpen)}
        >
            <g>
                <g>
                    <path d="M518.462,22.82H0l193.159,203.495l-0.014,269.327l132.173-68.37l-0.014-200.957L518.462,22.82z M212.837,463.286
                                l0.014-244.827L45.846,42.512h426.769L305.611,218.459l0.014,196.832L212.837,463.286z"/>
                </g>
            </g>
        </svg>
    )
};
const FilterOptions = (props) => {
    if(props?.gridRef?.current?.props?.rowData.length === 0) return <div/>;

    return (
        <div>{
            props.isOpen
                ? <div className={'filter-options-container'} style={{width: window.innerWidth * 0.9}}>
                    <div className={'filter-options-list'}>{
                        Object.keys(props?.gridRef?.current?.props?.rowData[0]).map(key => {
                            if(!props.gridRef.current.props.localeText[key]) return;
                            let headerName = props.gridRef.current.props.localeText[key] || undefined;
                            let isChecked = props.gridRef.current.api.columnModel.columnDefs.filter(column => column.field === key).length > 0;

                            return (
                                <div key={key} className={'filter-options'}>
                                    <input
                                        type="checkbox"
                                        id={key}
                                        checked={isChecked}
                                        name={headerName}
                                        onChange={e => {
                                            let nextColumnDefs = [...props.gridRef.current.api.columnModel.columnDefs];

                                            if(!e.target.checked) {
                                                let index = nextColumnDefs.findIndex(column => column.field === e.target.id);
                                                if(index > 0) nextColumnDefs.splice(index, 1);
                                            } else {
                                                nextColumnDefs.push({field: key, headerName, sort: "desc", suppressSizeToFit: true});
                                            }

                                            // console.log(nextColumnDefs);
                                            props.gridRef.current.api.setColumnDefs(nextColumnDefs);
                                            props.gridRef.current.api.redrawRows(props.gridRef);
                                            props.setUpdate(nextColumnDefs);
                                        }}
                                    />
                                    <label htmlFor={headerName}>{headerName}</label>
                                </div>
                            )
                        })
                    }</div>
                </div>
                : <div />
        }
        </div>
    )
};


export default (params) => {
    const [isOpen, setIsOpen] = useState(false);
    const [update, setUpdate] = useState(false);
    // console.log('Filter', params);
    // console.log('render', params?.gridRef?.current?.api);
    return (
        <div className={'filter-container'} >
            <FilterButton isOpen={isOpen} setIsOpen={setIsOpen} />
            <FilterOptions isOpen={isOpen} gridRef={params.gridRef} setUpdate={setUpdate}/>
        </div>
    );
};

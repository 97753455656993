export function authUser(credential) {
    const api = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_HOST_PROD : process.env.REACT_APP_API_HOST_DEV;

    return fetch(api, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
            "Content-Type": "application/json",
            'Authentication': 'credential',
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *client
        body: JSON.stringify(credential), // body data type must match "Content-Type" header
    })
        .then(data => data.json())
        // .then(data => {
        //     if(!data.ok) return undefined;
        //     if(data.ok) return data.json();
        // })
        .catch(err => console.log('authUser', err))
}


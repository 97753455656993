import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode";
import {authUser} from '../../request/authUser'

function Login(props) {
    return (
        <div style={{
            display: "flex",
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: window.innerHeight,
        }}>
            <div style={{color: 'green', margin: props.message.length > 0 ? 10 : 0}}>{props.message}</div>
            <GoogleLogin
                onSuccess={credentialResponse => {
                    let credential = jwt_decode(credentialResponse.credential);

                    authUser(credential)
                        .then(user => props.handleUser(user))
                        .catch(err => console.log('Login:', err))
                }}
                onError={() => {
                    console.log('Login Failed');
                }}
            />
        </div>
    );
}

export default Login;
